<template>
  <div>
    <div class="holder">
      <SmallNav />
      <div class="container">
        <div class="card_holder">
          <div class="card">
            <div class="container">
              <div class="payment_text_header">
                <h5>Rent Payment Summary</h5>
              </div>
              <hr />
              <div class="property_details_holder">
                <div class="row">
                  <div class="col-md-6">
                    <div class="property_img">
                      <img
                        src="../assets/images/prop1.svg"
                        alt="propertyimage"
                      />
                    </div>
                  </div>
                  <div class="col-md-6 row_wrapp">
                    <div class="property_details">
                      <h3>3 Bedroom flat</h3>
                      <h6>Plot 24b Gana Street, Maitama, Abuja.</h6>
                      <h4>&#8358;1,650,000 Biannually</h4>
                      <p>Payment due date: <span> 15th June, 2021</span></p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="plan_holder">
                <div class="plan_switch_holder">
                  <div>
                    <span class="rate_label">Annual Rate</span>
                    <button class="btn plan_btn">
                      <span class="plan_amount_btn">&#8358;3,000,000</span>

                      <span class="plan_icon">
                        <img src="../assets/images/luck.svg" alt="" />
                        <!-- <i class="fa fa-check" aria-hidden="true"></i> -->
                        <!-- <i class="fa-light fa-circle-check"></i> -->
                      </span>
                    </button>
                  </div>
                  <div>
                    <span class="rate_label">Bi-Annual Rate</span>
                    <button class="btn plan_btn">
                      <span class="plan_amount_btn">&#8358;3,000,000</span>

                      <span class="plan_icon">
                        <img src="../assets/images/luck.svg" alt="" />
                        <!-- <i class="fa fa-check" aria-hidden="true"></i> -->
                        <!-- <i class="fa-light fa-circle-check"></i> -->
                      </span>
                    </button>
                  </div>
                  <div>
                    <span class="rate_label">Quarterly Rate</span>
                    <button class="btn plan_btn active_plan">
                      <span class="plan_amount_btn">&#8358;8,400,000</span>

                      <span class="plan_icon">
                        <img src="../assets/images/circle-checked.svg" alt="" />
                        <!-- <i class="fa fa-check" aria-hidden="true"></i> -->
                        <!-- <i class="fa-light fa-circle-check"></i> -->
                      </span>
                    </button>
                  </div>
                  <div>
                    <span class="rate_label">Monthlyl Rate</span>
                    <button class="btn plan_btn">
                      <span class="plan_amount_btn">&#8358;220,000</span>

                      <span class="plan_icon">
                        <img
                          src="../assets/images/circle-unchecked.svg"
                          alt=""
                        />
                      </span>
                    </button>
                  </div>
                </div>
              </div>

              <div class="charges_holder">
                <div class="charges">
                  <h6>Amount due (Quaterly Plan)</h6>
                  <small>#1,650,000</small>
                </div>
                <hr />
                <div class="charges">
                  <h6>Service charge (1%)</h6>
                  <small>#16,500</small>
                </div>
                <hr />
                <div class="charges">
                  <h6>Total payable</h6>
                  <small>#1,800,000</small>
                </div>
                <hr />

                <div class="proceed_btn">
                  <router-link to="switchagreement">
                    <button class="btn">PROCEED</button>
                  </router-link>
                </div>
              </div>
              <div class="payment_schedule_holder">
                <div class="schedule_header">
                  <h5>Rent Payment Schedule</h5>
                  <img src="../assets/images/switch-icon.svg" alt="" />
                </div>
                <div class="schedule_period">
                  <div
                    v-for="(item, index) in periods"
                    class="color__item"
                    :key="index"
                  >
                    <h6>{{ item.duration }}</h6>
                    <small>&#8358;{{ item.amount }}</small>
                  </div>
                </div>
                <div class="schedule_footer">
                  <h5>View All</h5>
                  <h3>Total Outstanding: #1,800,000</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SmallNav from "./SmallNav";
import { Service } from "../store/service";
const Api = new Service();
import { EventBus } from "../main";
import $ from "jquery";
export default {
  name: "SwitchPropertySummary",
  components: {
    SmallNav
    // FooterMain,
  },
  data() {
    return {
      periods: [
        {
          duration: "15th May, 2022 (90 days away)",
          amount: "#1,650,000"
        },
        {
          duration: "20th June, 2022 (90 days away)",
          amount: "#1,650,000"
        },
        {
          duration: "15th May, 2022 (90 days away)",
          amount: "#1,650,000"
        }
      ],

      data: ""
    };
  },
  //   props: {
  //   keyword: {}
  // },

  created() {
    EventBus.$on("gotclicked", data => {
      // console.log("DATA", data);
      this.data = data;
      localStorage.setItem("wegetdata", JSON.stringify(data));
      // console.log("data with eventbus:", data);
      // if (data != '') {
      //    this.data = data;
      //   console.log("data with eventbus:", data);
      // } else {
      //   EventBus.$off("gotclicked", this.data);
      //   console.log("EventBus Offf");
      //   window.history.back();
      // }
      // this.data = data;
      //   console.log("data with eventbus:", data);
    });
  },
  // destroyed() {
  //   EventBus.$off("gotclicked", this.data);
  //    window.history.back();
  // },

  mounted() {
    setTimeout(() => {
      if (localStorage.removeItem("wegetdata")) {
        if (this.$router) {
        }
        window.history.back();
      }
    }, "5000");

    //  EventBus.$on('gotclicked', data => {
    //     console.log("DATA", data);
    //      this.data = data;
    //       console.log("data with eventbus:", data);

    //   })
    if (this.data) {
      //  window.history.back();
      // console.log("Mounted data", this.data);
    }
    // console.log("Mounted data2", this.data);
    //  if (this.data === '') {
    //    this.destroyed();
    //  }
    //  this.hello();
    //  setTimeout(() => {
    //    if (this.data != "") {
    //    console.log("data dey")
    //    this.hello();
    //  } else if(this.data === '') {
    //     console.log("No data")
    //     // window.history.back();
    //  }
    //   }, "4000")

    //  if (this.data != "") {
    //    console.log("data dey")
    //    this.hello();
    //  } else if(this.data === '') {
    //     console.log("No data")
    //     // window.history.back();
    //  }
    //     this.$root.$on('keywordEvent', (data) => {
    //     if (data === '') {
    //       window.history.back();
    //     } else {
    //        console.log("Function data", data);
    //       this.data = data
    //       console.log("datatatatata", this.data);

    //     }

    //  })
  },
  methods: {
    hello() {
      this.$root.$on("keywordEvent", data => {
        // this.data = data
        if (this.data != "") {
          this.data = data;
          console.log("datatatatata", this.data);
        } else if (this.data === "") {
          console.log("move");
          // window.history.back();
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
$primary: #0033ea;
$secondary: #ffffff;
$tatiary: #a2b0e3;
$black: #000000;
$black2: #222222;

@font-face {
  font-family: "Gotham-bold";
  src: local("Gotham-bold"),
    url("../assets/fonts/shelta_fonts/Gotham\ Bold.otf") format("truetype");
}
@font-face {
  font-family: "Gotham";
  src: local("Gotham"),
    url("../assets/fonts/shelta_fonts/Gotham\ Book.otf") format("truetype");
}
@font-face {
  font-family: "Gotham-medium";
  src: local("Gotham-medium"),
    url("../assets/fonts/shelta_fonts/Gotham\ Medium.otf") format("truetype");
}

.holder {
  height: 100%;
}

.card_holder {
  max-width: 743px;
  width: 100%;
  margin: auto;
  padding-top: 49px;
}

.payment_text_header {
  h5 {
    font-family: "Gotham";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 30px;
    display: flex;
    align-items: center;
    padding-top: 25px;
  }
}

.property_details_holder {
  .row {
    align-items: center;
  }
}

.property_img {
  img {
    width: 100%;
    border-radius: 5px;
  }
}

.property_details {
  h3 {
    font-family: "Gotham";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.8);
    text-align: left;
    // margin-bottom: 0;
  }
  h6 {
    font-family: "Gotham";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.8);
    text-align: left;
    margin-bottom: 0;
  }
  h4 {
    font-family: "Gotham";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 50px;
    color: $black;
    text-align: left;
    margin-bottom: 0;
  }
  p {
    font-family: "Gotham";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 13px;
    display: flex;
    align-items: center;
    color: $black;
    text-align: left;
    margin-bottom: 0;
    span {
      font-weight: 600;
      margin-left: 5px;
    }
  }
}

.plan_holder {
  margin-top: 41px;
}
.rate_label {
  font-family: "Gotham";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 25px;
  letter-spacing: -0.05em;
  color: $black;
  display: flex;
}

.plan_btn {
  display: flex;
  justify-content: space-between;
  background: #f5f5f5;
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  //   width: 100%;
  height: 50px;
  box-shadow: none;
  outline: 0;
  align-items: center;
  margin-bottom: 23px;
}

.plan_amount_btn {
  font-family: "Gotham";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  margin-right: 15px;
  // line-height: 50px;
  color: $black;
}

.plan_switch_holder {
  display: flex;
  justify-content: space-between;
}

.charges_holder {
  margin-top: 30px;
}
.charges {
  display: flex;
  justify-content: space-between;
  align-items: center;
  h6 {
    font-family: "Gotham";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 30px;
    color: $black;
    margin-bottom: 0;
  }
  small {
    font-family: "Gotham";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    text-align: center;
    color: $black;
  }
}

.proceed_btn {
  text-align: right;
  button {
    max-width: 166px;
    width: 100%;
    background: $primary;
    color: $secondary;
    font-family: "Gotham";
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    box-shadow: none;
    outline: 0;
  }
}

.payment_schedule_holder {
  margin-top: 30px;
  margin-bottom: 27px;
}

.schedule_header {
  background: linear-gradient(180.48deg, #000000 -92.86%, #0033ea 130.64%),
    #ffffff;
  border-radius: 10px 10px 0px 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 21px 7px 21px;
  h5 {
    color: $secondary;
    font-family: "Gotham";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 15px;
  }
}

.schedule_period {
  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 14px 17px;
    h6 {
      font-family: "Gotham";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 30px;
      color: $black;
      margin-bottom: 0;
    }
    small {
      font-family: "Gotham";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 30px;
      color: $black;
    }
  }
}

.schedule_footer {
  background: linear-gradient(180.48deg, #000000 -92.86%, #0033ea 130.64%),
    #ffffff;
  border-radius: 0px 0px 10px 10px;
  // transform: matrix(1, 0, 0, 1, 0, 0);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 21px 7px 21px;
  h5 {
    color: $secondary;
    font-family: "Gotham";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 30px;
  }
  h3 {
    color: $secondary;
    font-family: "Gotham";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 30px;
  }
}

.color__item {
  &:nth-of-type(1),
  &:nth-child(2n + 1) {
    background: linear-gradient(0deg, #efefef, #efefef),
      linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%) !important;
  }
  &:nth-of-type(2),
  &:nth-child(2n + 2) {
    background: $secondary !important;
  }
}

@media screen and (max-width: 599px) {
  .card_holder {
    padding-top: 10px;
  }
  .plan_switch_holder {
    display: block;
    .plan_btn {
      width: 100%;
    }
  }
  .row_wrapp {
    margin-top: 20px;
  }
  .plan_amount_btn {
    font-size: 15px;
  }
  .plan_icon {
    img {
      width: 75%;
    }
  }
  .proceed_btn {
    button {
      max-width: 116px;
      font-size: 12px;
    }
  }
  .charges {
    h6 {
      font-size: 12px;
      text-align: left;
    }
    small {
      font-size: 12px;
    }
  }
  .schedule_period {
    div {
      h6 {
        font-size: 10px;
      }
      small {
        font-size: 10px;
      }
    }
  }
  .schedule_footer {
    flex-direction: column-reverse;
  }
}
</style>
